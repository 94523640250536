import {
  EmailSignUpActions,
  EmailSignUpLocations,
  SignUpFormAnalyticsEventType,
  SignUpFormAnalyticsIdentifiers,
  SignUpLocations,
} from 'types/analyticTypes';

import { event, getPageType, hashEmail } from './analytics-utils';
import { AnalyticsEvents } from './analytics.types';

export const emailSignUpEvent = (
  action: EmailSignUpActions,
  location?: keyof typeof EmailSignUpLocations
) => {
  if (!location) return;

  event(AnalyticsEvents.EMAIL_SIGNUP, {
    emailAction: action,
    signUpLocation: EmailSignUpLocations[location],
  });
};
export const signUpFormAnalyticsEvent = ({
  identifier,
  action,
  location,
}: SignUpFormAnalyticsEventType) => {
  if (!location) return;
  const signUpLocation = SignUpLocations[location];
  switch (identifier || SignUpFormAnalyticsIdentifiers.EMAIL_SIGNUP) {
    case SignUpFormAnalyticsIdentifiers.SMS_OPT_IN: {
      event(AnalyticsEvents.SMS_OPT_IN, {
        smsAction: `sms ${action}`,
        signUpLocation,
      });
      break;
    }
    case SignUpFormAnalyticsIdentifiers.EMAIL_SIGNUP: {
      event(AnalyticsEvents.EMAIL_SIGNUP, {
        emailAction: `email ${action}`,
        signUpLocation,
      });
      break;
    }
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unhandled Sign Up Form Identifier: ${identifier}`);
  }
};

export const logoutEvent = (
  customerEmail?: string | null,
  customerId?: string,
  method?: string
) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.LOGOUT,
    event_params: {
      method: method,
      is_guest: 'true',
      hashed_email: hashEmail(customerEmail),
      custom_user_id: customerId,
    },
  });
};

export const loginEvent = (
  url: string,
  hashedEmail?: string | null,
  customUserId?: string
) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.LOGIN,
    event_params: {
      method: getPageType(url),
      is_guest: 'false',
      hashed_email: hashEmail(hashedEmail),
      custom_user_id: customUserId,
    },
  });
};
