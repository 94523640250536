import {
  QuiltRoot,
  QuiltViewport,
  QuiltCell,
  TileRoot,
  TileMedia,
  TileContent,
  TileBody,
  TileHeading,
  TileDescription,
  TileActionList,
  QuiltLayout,
  Link,
  LinkInvBlock,
  LayoutMasonry,
} from '@global-ecom/nitro-uds/elements';
import { tw } from 'twind';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import { Ga4Data, usePromotionSelect } from 'hooks/usePromotionSelect';
import { getIsMobile } from 'utils/media';
import {
  isDateBetween,
  isDateBefore,
  IsDateAfter,
} from 'utils/dateCalculations';
import { isNotNullish } from 'utils/isNotNullish';
import {
  QuiltDocumentType,
  QuiltLayoutType,
  TileMediaRatio,
} from 'groq/documents/QuiltDocument';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { PromoTileDocumentType } from 'groq/documents/PromoTileDocument';
import { CategoryDocumentType } from 'groq/documents/Category';
import { SanityImage } from 'ui/components/SanityImage';
import { getPageType } from 'utils/analytics';
import { useSiteConfig } from 'hooks/useSiteConfig';

const configure = (media: QuiltLayoutType): QuiltLayout => {
  if (!media?.layout)
    return {
      gap: 'base',
      layout: 'stack',
    };

  if (media.layout.startsWith('masonry')) {
    const [, align] = media.layout.split('-');
    return {
      align: align as LayoutMasonry['align'],
      gap: media.gap,
      layout: 'masonry',
    };
  }

  const [layoutType, columns] = media.layout.split('-');
  return {
    columns: parseInt(columns),
    gap: media.gap,
    layout: layoutType as QuiltLayout['layout'],
    limit: media.limit,
  };
};

const showTile = (availableFrom, availableTo, dateToCheck) => {
  if (isNotNullish(availableFrom)) {
    const startDate = new Date(availableFrom);
    if (isNotNullish(availableTo)) {
      return isDateBetween({
        startDate,
        endDate: new Date(availableTo),
        dateToCheck,
      });
    } else {
      return IsDateAfter({ dateToCheck, startDate });
    }
  } else if (isNotNullish(availableTo)) {
    return isDateBefore({ dateToCheck, endDate: new Date(availableTo) });
  }
  return true;
};

export const Quilt = (props: QuiltDocumentType) => {
  const {
    _id,
    _type,
    items,
    mobile,
    tablet,
    desktop,
    inverted,
    tileLayout,
    tileMediaRatio,
    contentJustify,
  } = props;

  const router = useRouter();
  const dateToCheck = router.query.viewAt
    ? new Date(parseInt(router.query.viewAt as string))
    : new Date();
  const isMobile = getIsMobile();

  const promotion_id = _id || '';
  const promotion_name = '';
  const creative_name = _type || 'Quilt';

  const quiltRef = useRef(null);

  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  usePromotionView(quiltRef, gaTrackData, true);

  return (
    <div ref={quiltRef}>
      <QuiltRoot
        config={{
          mobile: configure(mobile),
          tablet: configure(tablet),
          desktop: configure(desktop),
        }}
      >
        <Scrollbar
          translateContentSizeYToHolder
          removeTracksWhenNotUsed
          disableTracksWidthCompensation
          disableTrackYMousewheelScrolling
          trackXProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="TrackX"
                  style={{ ...style, height: 6, left: 0, width: '100%' }}
                />
              );
            },
          }}
          scrollerProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="MyAwesomeScrollbarsScroller" // Default library className
                  style={{
                    ...style,
                    scrollSnapType: 'x mandatory',
                    scrollBehavior: 'smooth',
                  }}
                />
              );
            },
          }}
          contentProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="Content pb-4"
                  style={{ ...style, display: 'block' }}
                />
              );
            },
          }}
          thumbXProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return (
                <div
                  {...restProps}
                  ref={elementRef}
                  className="ThUmBX"
                  style={{ ...style, background: 'rgba(0, 0, 0, 0.65)' }}
                />
              );
            },
          }}
        >
          <QuiltViewport
            asChild
            className={tw(
              mobile.layout.startsWith('scroll') && 'mobile:pb-6',
              tablet.layout.startsWith('scroll') && 'tablet:pb-6',
              desktop.layout.startsWith('scroll') && 'desktop:pb-8',
              'hide-scrollbar overflow-x-visible!'
            )}
          >
            <ul>
              {items
                .filter(item =>
                  showTile(item.availableFrom, item.availableTo, dateToCheck)
                )
                .map((item, i) => {
                  return (
                    <QuiltTile
                      {...item}
                      isMobile={isMobile}
                      tileLayout={tileLayout}
                      tileMediaRatio={tileMediaRatio}
                      inverted={inverted}
                      contentJustify={contentJustify}
                      key={i}
                    />
                  );
                })}
            </ul>
          </QuiltViewport>
        </Scrollbar>
      </QuiltRoot>
    </div>
  );
};

type QuiltTileProps = (PromoTileDocumentType | CategoryDocumentType) & {
  isMobile: boolean;
  tileLayout: string;
  tileMediaRatio?: TileMediaRatio;
  inverted: boolean;
  contentJustify: 'center' | 'start' | 'end';
};

const normalizeType = (item: QuiltTileProps) => {
  switch (item._type) {
    case 'PromoTile':
      return {
        id: item._id,
        color: item.backgroundColor,
        media: item.backgroundImage,
        mobileMedia: item.mobileBackgroundImage,
        heading: item.header,
        description: item.copy,
        title: item.cta?.title,
        link: item.cta?.link,
      };
    case 'category':
      return {
        id: item._id,
        heading: item.name,
        link: item.url,
        media: item.image,
      };
    default:
      return {};
  }
};

const QuiltTile = (props: QuiltTileProps) => {
  const quiltTileRef = useRef(null);

  const normalized = normalizeType(props);

  const promotion_id = normalized.id || '';
  const promotion_name = normalized.heading || '';
  const creative_name = 'QuiltTile';

  const gaTrackData: GaTrackData = {
    id: promotion_id,
    name: promotion_name,
    creative: creative_name,
  };

  const { ga4PromotionTrackerPosition } = usePromotionView(
    quiltTileRef,
    gaTrackData,
    true
  );

  const router = useRouter();

  const { localizeUrlPath } = useSiteConfig();

  const link_page_type = normalized.link
    ? getPageType(localizeUrlPath(normalized.link))
    : undefined;

  const setPromotion = link_page_type === 'plp';

  const ga4Data: Ga4Data = {
    creative_name,
    creative_slot: ga4PromotionTrackerPosition,
    promotion_id,
    promotion_name,
    cta_click: undefined,
    fireEventFromPdp: router.asPath.includes('/pd/'),
    link_url: normalized.link,
    link_page_type,
    ...(setPromotion && { setPromotion }),
  };

  const { promotionSelectEvent } = usePromotionSelect();

  return (
    <QuiltCell ref={quiltTileRef} asChild>
      <li>
        <TileRoot
          className="h-full"
          fill="media"
          invert={props.inverted}
          layout={props.tileLayout}
        >
          <TileMedia
            className={normalized.color ? `bg-[${normalized.color}]` : ''}
            ratio={props.tileMediaRatio}
            vignette={props.tileLayout === 'overlay'}
          >
            <SanityImage
              source={normalized.media}
              mobileSource={normalized.mobileMedia}
            />
          </TileMedia>
          {!!normalized.link && (
            <LinkInvBlock
              href={normalized.link}
              focusable={!normalized?.link}
              onClick={() => promotionSelectEvent({ ...ga4Data })}
            />
          )}
          <TileContent
            justify={props.contentJustify}
            insetX={
              props.tileLayout === 'stack' &&
              !props.inverted &&
              props.contentJustify !== 'center'
                ? false
                : true
            }
          >
            <TileBody>
              {normalized.heading && (
                <TileHeading balance condensed>
                  {normalized.heading}
                </TileHeading>
              )}
              {normalized.description && (
                <TileDescription balance>
                  {normalized.description}
                </TileDescription>
              )}
            </TileBody>
            {normalized?.title && (
              <TileActionList>
                <Link
                  href={normalized.link || ''}
                  label={normalized?.title}
                  onClick={() => promotionSelectEvent({ ...ga4Data })}
                />
              </TileActionList>
            )}
          </TileContent>
        </TileRoot>
      </li>
    </QuiltCell>
  );
};
