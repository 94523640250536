import crypto from 'crypto';

import {
  DisplayOutOfStock,
  Variant,
  VariantProduct,
} from '__generated__/graphql';
import { ProductTypeFromPDPQuery } from 'types/product';
import { REFERRER_CATEGORY, REFERRER_TYPE, isServer } from 'utils/constants';
import {
  Dimensions,
  AnalyticsDimensions,
  PageViewPayload,
  AnalyticsPageType,
  pathType,
  ItemAvailabilityValue,
  AdditionalDimensionArgs,
  ItemAvailabilitySchema,
} from 'types/analyticTypes';
import { lowerCaseOrBlank, upperCaseOrBlank } from 'utils/string';
import { getSizesFromSizeGroups } from 'utils/products';

import { AnalyticsEvents, AnalyticsEventValues } from './analytics.types';

export const getPageType = (url: string): AnalyticsPageType => {
  const baseUrl = url.toLowerCase();

  switch (true) {
    case baseUrl.includes('/checkout/confirmation'):
      return 'order confirmation';
    case baseUrl.includes('/checkout/payment'):
      return 'payment';
    case baseUrl.includes('/checkout'):
      return 'checkout';
    case baseUrl.includes('/pd/'):
      return 'pdp';
    case baseUrl.includes('/account'):
      return 'account';
    case baseUrl.includes('/search'):
      return 'search';
    case baseUrl.includes('/wishlist'):
    case baseUrl.includes('/returns'):
      return 'other';
    case baseUrl.includes('/cart'):
      return 'cart';
    case baseUrl.includes('/help'):
      return 'content';
    case baseUrl.split('/').length === 3:
      return 'home';
    default:
      return 'plp';
  }
};

export const hashEmail = (
  email: string | undefined | null
): string | undefined => {
  if (email) {
    const hash = crypto.createHash('sha256');
    hash.update(email);

    const hashedEmail = hash.digest('hex');

    return hashedEmail;
  }
};

export const activateOptimize = () => {
  window.dataLayer.push({
    event: 'optimize.activate',
  });
};

export const getAdditionalAnalyticsDimensions = ({
  sizeName,
  displayOutOfStock,
  assortmentAvailability,
  isProductMarkedDown,
  orderable,
  ean,
}: AdditionalDimensionArgs) => ({
  [Dimensions.d88]: upperCaseOrBlank(sizeName),
  [Dimensions.d92]: getItemAvailability(displayOutOfStock || undefined),
  [Dimensions.d94]: lowerCaseOrBlank(assortmentAvailability),
  [Dimensions.d96]: lowerCaseOrBlank(`${isProductMarkedDown ?? ''}`),
  [Dimensions.d97]: lowerCaseOrBlank(`${orderable}`),
  [Dimensions.d100]: getAddToCardReferrer(ean),
});

export const getAnalyticsDimensions = (
  product: VariantProduct
): AnalyticsDimensions => {
  const productPrice = product?.productPrice?.price;
  const productSalePrice = product?.productPrice?.salePrice;
  const isProductMarkedDown = !!(
    productPrice &&
    productSalePrice &&
    productSalePrice < productPrice
  );

  return {
    [Dimensions.d14]: lowerCaseOrBlank(product.name),
    [Dimensions.d15]: lowerCaseOrBlank(product.masterId),
    [Dimensions.d16]: lowerCaseOrBlank(product.sportName),
    [Dimensions.d17]: lowerCaseOrBlank(product.technology),
    [Dimensions.d18]: lowerCaseOrBlank(product.gender),
    [Dimensions.d19]: lowerCaseOrBlank(product.ageGroup),
    [Dimensions.d20]: lowerCaseOrBlank(product.lineName),
    [Dimensions.d21]: lowerCaseOrBlank(product.businessSegment),
    [Dimensions.d22]: lowerCaseOrBlank(product.division),
    [Dimensions.d23]: lowerCaseOrBlank(product.articleGroup),
    [Dimensions.d24]: lowerCaseOrBlank(product.articleType),
    [Dimensions.d25]: lowerCaseOrBlank(product.colorDescription),
    [Dimensions.d76]: lowerCaseOrBlank(product.colorName),
    [Dimensions.d79]: lowerCaseOrBlank(product.businessUnit),
    [Dimensions.d80]: lowerCaseOrBlank(product.businessSubSegment),
    [Dimensions.d81]: lowerCaseOrBlank(product.franchise),
    [Dimensions.d82]: lowerCaseOrBlank(product.collectionMark),
    ...getAdditionalAnalyticsDimensions({
      sizeName: product.size.label,
      orderable: product.orderable,
      displayOutOfStock: product.displayOutOfStock,
      assortmentAvailability: product.assortmentAvailability,
      isProductMarkedDown,
      ean: product.ean,
    }),
  };
};

export const getItemAvailability = (
  val?: DisplayOutOfStock
): ItemAvailabilityValue | '' => {
  if (!val) return ItemAvailabilitySchema.instock;
  const itemAvailabilityValue = Object.keys(val).filter(key => val[key])[0];
  return ItemAvailabilitySchema[itemAvailabilityValue] ?? '';
};

export const getCommonFitAnalyticsData = (
  shopLanguage = '',
  shopCountry = '',
  currency = '',
  userId = '',
  sessionId?: string
) => ({
  shopLanguage,
  shopCountry,
  currency,
  userId,
  sessionId: sessionId || userId,
});

export const getPDPFitAnalyticsData = (
  masterProduct: ProductTypeFromPDPQuery,
  variation: Variant,
  swatch: string
) => ({
  currentProductId: variation.styleNumber ?? '',
  mainThumbnail: variation.images[0]?.href ?? '',
  allProductIds: masterProduct?.variations.map(
    variation => variation.styleNumber ?? ''
  ),
  sizes: masterProduct?.variations
    .filter(variation => variation.colorValue === swatch)
    .map((variation: unknown) => {
      const retypedVariation = variation as Variant;
      const sizes = getSizesFromSizeGroups(retypedVariation?.sizeGroups);

      return {
        length: null,
        values: sizes.map(size => size.label),
        availability: sizes.reduce((acc, curr) => {
          return { ...acc, [curr.label]: curr.orderable };
        }, {}),
      };
    }),
});

export const getAddToCardReferrer = (ean: string | undefined | null) => {
  if (ean && window?.dataLayer?.length) {
    const listDataLayer = window.dataLayer.filter(
      e => e.event === AnalyticsEvents.ADD_TO_CART
    );
    const vectorProductsEvent = listDataLayer.map(
      (element: Record<string, any>) => {
        const rObj = { ean: '', dimension100: '' };
        rObj['ean'] = element?.ecommerce?.add?.products[0]?.ean;
        rObj['dimension100'] =
          element?.ecommerce?.add?.products[0]?.dimension100;

        return rObj;
      }
    );
    const result = vectorProductsEvent.filter(item => item.ean === ean);
    return result[0]?.dimension100;
  } else {
    return '';
  }
};

const ga4GenericEvent = (
  url: string,
  payload: PageViewPayload,
  pageType: AnalyticsPageType
) => {
  const isGuest = String(!payload.customerAuth);

  const query_string = getG4aPath(url, 'query string');

  const referrerCategory =
    window.localStorage.getItem(REFERRER_CATEGORY) ?? undefined;
  const referrerType = window.localStorage.getItem(REFERRER_TYPE) ?? undefined;

  window.dataLayer.push({
    event: AnalyticsEvents.GA4_GENERIC,
    page_type: pageType,
    page_title: payload.pageTitle,
    page_path: getG4aPath(url, 'page path'),
    page_location: payload.currentURL,
    query_string,
    local_language: payload.language,
    custom_user_id: getCustomerId(payload),
    store_view: `${payload.language}-${payload.country.toUpperCase()}`,
    is_guest: isGuest,
    release_version: window.sessionStorage.getItem('analyticsReleaseVersion'),
    web_frontend: 'cybercat',
    index_status: getIndexStatus(),
    gsm_code: getGsmCode(payload.country),
    hashed_email: hashEmail(payload.customerEmail),
    referrer_category: referrerCategory,
    referrer_type: referrerType,
  });
};

export const getG4aPath = (url: string, pathType: pathType) => {
  const splittedUrl = url.split('?');
  if (pathType === 'page path') return splittedUrl[0];
  else return splittedUrl[1];
};
export const getCustomerId = (payload: PageViewPayload) => {
  const storedCustomerId = localStorage.getItem('customerID');
  if (!payload.customerAuth) {
    return storedCustomerId || `guest_${payload.customerId}`;
  }
  if (!storedCustomerId || storedCustomerId !== payload.customerId) {
    localStorage.setItem('customerID', payload.customerId);
  }
  return localStorage.getItem('customerID') || '';
};

export const pageview = (
  url: string,
  payload: PageViewPayload,
  pageTypeOverride?: AnalyticsPageType
) => {
  const pageType = pageTypeOverride ?? getPageType(url);

  ga4GenericEvent(url, payload, pageType);

  event(AnalyticsEvents.GENERAL, {
    pageType,
    pageTitle: payload.pageTitle,
    language: payload.language,
    customerID: payload.customerId,
    customerAuth: payload.customerAuth,
    customerEmail: payload.customerEmail,
    customerNo: payload.customerNo,
    storeView: payload.country,
    country: payload.country.toUpperCase() + ' Shop',
    environment: process.env.STAGE,
    releaseVersion: window.sessionStorage.getItem('analyticsReleaseVersion'),
    frontend: 'cybercat',
    breadcrumbs: payload.breadcrumbs,
    viewport: getViewport(),
    searchQuery: payload.searchQuery,
    cart: payload.cart,
    currentURL: payload.currentURL,
  });
};
const gsmCodes = {
  ca: 'CARE0003',
  us: 'USRE0001',
  uk: 'GBRE0006',
  jp: 'JPRE0006',
  in: 'INRE0001',
  au: 'AURE0001',
  nz: 'NZRE0002',
} as const;

const getGsmCode = (countryCode: string) => {
  const country = countryCode.split('-')[0];

  return gsmCodes[country];
};

const getIndexStatus = () => {
  if (document) {
    const robots = document.getElementsByName('robots')[0]?.['content'];
    if (robots === 'noindex, nofollow') {
      return 'noindex';
    } else {
      return 'index';
    }
  }
};

export const event = (type: AnalyticsEventValues, payload?: any) => {
  const isGA4Event =
    type.startsWith('GA4EC_') || type === AnalyticsEvents.GA4_CustomEvent;
  const eventData = {
    event: type,
    ...(!isGA4Event && {
      environment: process.env.STAGE,
      release_version: window.sessionStorage.getItem('analyticsReleaseVersion'),
      viewport: getViewport(),
    }),
    ...payload,
  };
  if (!isServer) window.dataLayer.push(eventData);
};

const getViewport = () => {
  const minScreenSize = Math.min(window.screen.width, window.screen.height);

  if (minScreenSize < 480) {
    return 'mobile';
  } else if (minScreenSize < 1024) {
    return 'tablet';
  }
  return 'desktop';
};

export const removeNullUndefined = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
